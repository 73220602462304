import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { AccountStatus, AccountType } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  BusinessModel,
  UserModel,
} from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { Card, CardBody } from "components/common/Card";
import { useToast } from "hooks/useToast";
import React, { useEffect, useMemo, useState } from "react";
import { RiCheckFill, RiFileCopyFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { accountService } from "services";

interface VirtualAccountCardProps {
  business: BusinessModel;
  user: UserModel | null;
  admin?: AdministratorModel;
}

export const VirtualAccountCard: React.FC<VirtualAccountCardProps> = ({
  business,
  user,
  admin,
}) => {
  const { toast } = useToast();
  const {
    data: virtualAccounts,
    refetch,
    isLoading,
  } = useQuery(
    "account_read_by_type",
    () => accountService.readByOwnerType(business._id, AccountType.DNUBAN),
    { retry: false, enabled: !!business._id }
  );

  const virtualAccount = useMemo(() => {
    if (!virtualAccounts) return null;
    try {
      return JSON.parse(virtualAccounts?.[0].meta);
    } catch (error) {
      return null;
    }
  }, [virtualAccounts]);

  const {
    mutate: generateVirtualAccount,
    isLoading: generatingVirtualAccount,
  } = useMutation(accountService.generateDNUBAN);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const copiedTimeout = setTimeout(() => {
        setCopied(false);
        clearTimeout(copiedTimeout);
      }, 5000);
    }
  }, [copied]);

  const handleGenerateVirtualAccount = () => {
    if (!user) {
      toast({
        status: "error",
        description: "Business administrator not found",
      });
      return;
    }
    generateVirtualAccount(
      {
        owner: user.business!._id,
        meta: JSON.stringify({
          business_name: user.business!.name,
          phone: user.phone!,
          email: user.email!,
        }),
        tag: "*",
        value: "*",
        status: AccountStatus.Active,
      },
      {
        onSuccess() {
          toast({
            status: "success",
            description: "Virtual account created successfully.",
          });
          refetch();
        },
        onError(error: any) {
          toast({
            status: "error",
            description:
              error?.response?.data?.message ??
              "An unexpected error has occurred.",
          });
        },
      }
    );
  };

  return isLoading ? (
    <Card>
      <ContentLoader isLoading />
    </Card>
  ) : virtualAccount ? (
    <Card>
      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text color="gray.700" fontWeight="medium">
              Virtual Account
            </Text>
            <Text fontWeight="medium">{virtualAccount?.bank?.name}</Text>
            <Text fontSize="lg" fontWeight="bold">
              {virtualAccount?.account_number}
            </Text>
            <Text fontSize="sm" fontWeight="medium">
              {virtualAccount?.account_name}
            </Text>
            {copied && (
              <Text fontSize="sm" color="success.600">
                Account number copied!
              </Text>
            )}
          </Box>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            padding="6px"
            bg={copied ? "success.50" : "primary.50"}
            borderRadius={999}
            cursor="default"
            onClick={() => {
              navigator.clipboard.writeText(virtualAccount?.account_number);
              setCopied(true);
            }}
          >
            <Icon
              as={copied ? RiCheckFill : RiFileCopyFill}
              color={copied ? "success.500" : "primary.500"}
              width="18px"
              height="18px"
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  ) : !!admin && !!user ? (
    <Button
      size="sm"
      mt="10px"
      colorScheme="primary"
      onClick={handleGenerateVirtualAccount}
      isLoading={generatingVirtualAccount}
      isDisabled={generatingVirtualAccount}
      width="100%"
    >
      Generate DNUBAN
    </Button>
  ) : null;
};
