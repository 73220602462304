import { Badge, BadgeProps } from "@chakra-ui/react";
import { AccountStatementStatus } from "@obtainly-v2/enums";
import { getAccountStatementStatus } from "utils";

export const AccountStatementStatusBadge = ({
  status,
  ...badgeProps
}: BadgeProps & {
  status: AccountStatementStatus;
}) => {
  return (
    <Badge
      colorScheme={
        [
          AccountStatementStatus.CashBackIn,
          AccountStatementStatus.PayIn,
        ].includes(status)
          ? "success"
          : "critical"
      }
      {...badgeProps}
    >
      {getAccountStatementStatus(status)}
    </Badge>
  );
};
