import { Button, VStack } from "@chakra-ui/react";
import { AccountStatus } from "@obtainly-v2/enums";
import {
  AccountModel,
  AdministratorModel,
  UserModel,
} from "@obtainly-v2/schema";
import { FormBaseProps, FormField, FormGroup } from "components/common";
import { useFormWithSchema } from "hooks";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { accountService } from "services";
import * as Yup from "yup";
import { BanksAutocomplete } from "./BanksAutocomplete";
import { VerifyAccount } from "./VerifyAccount";

interface PayoutAccountFormProps
  extends FormBaseProps<PayoutAccountFormType, AccountModel> {
  user: UserModel;
  admin?: AdministratorModel;
}

const formSchema = Yup.object({
  owner: Yup.string().required().label("Vendor"),
  status: Yup.string().required(),
  value: Yup.string()
    .matches(/^[0-9]+$/, (field) => `${field.label} must contain only digits`)
    .length(10, (field) => `${field.label} must be 10 digits`)
    .required()
    .label("Account number"), // account number
  tag: Yup.string()
    .matches(/[^$]/, (field) => `${field.label} currently unavailable`)
    .required()
    .label("Bank"), // bank code
  meta: Yup.string().required(),
});

type PayoutAccountFormType = Yup.InferType<typeof formSchema>;

export const PayoutAccountForm: React.FC<PayoutAccountFormProps> = ({
  user,
  admin,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const defaultValues: PayoutAccountFormType = {
    meta: JSON.stringify({
      business_name: user.business!.name,
      phone: user.phone!,
      email: user.email!,
    }),
    owner: user.business?._id,
    status: AccountStatus.Inactive,
    tag: "",
    value: "",
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset: resetForm,
    watch,
  } = useFormWithSchema(formSchema, { defaultValues });
  const bankCode = watch("tag");
  const accountNumber = watch("value");
  const [verified, setVerified] = useState(false);

  const { mutate: createPayoutAccount, isLoading: isCreatingPayoutAccount } =
    useMutation(accountService.generatePayoutAccount);

  const isSubmitting = isCreatingPayoutAccount || loading;

  const errorHandler = (error: any) => {
    const message = error?.response?.data?.message || "Unknown error occurred";
    onError?.(new Error(message));
    toast({
      description: message,
      status: "error",
    });
  };

  const successHandler = (
    formValues: PayoutAccountFormType,
    data: AccountModel,
    toastMessage: string
  ) => {
    onSuccess?.(data);
    resetForm(formValues);
    toast({
      description: toastMessage,
      status: "success",
    });
  };

  const commitChanges = (values: PayoutAccountFormType) => {
    createPayoutAccount(values, {
      onSuccess: ({ data }) => successHandler(defaultValues, data, "Created"),
      onError: errorHandler,
    });
  };

  const onFormSubmit = (values: PayoutAccountFormType) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(values)
        .then((data) => resetForm(data))
        .finally(() => setLoading(false));
    } else {
      commitChanges(values);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <VStack alignItems="stretch">
          {/* owner, meta, status and meta */}
          {/* these are set in defaultValues */}

          {/* tag  */}
          <FormGroup label="Bank">
            <Controller
              name="tag"
              control={control}
              render={({
                field: { ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <BanksAutocomplete
                  onChange={(data) => {
                    onChange({ target: data });
                  }}
                  errorMessage={error?.message}
                  {...rest}
                />
              )}
            />
          </FormGroup>

          {/* value */}
          <FormGroup label="Account number">
            <FormField
              autoComplete="value"
              placeholder="Enter account number"
              errorMessage={errors.value?.message}
              {...register("value")}
            />
            <VerifyAccount
              user={user}
              accountNumber={accountNumber}
              bankCode={bankCode}
              onVerify={(status) => setVerified(status)}
            />
          </FormGroup>

          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid || !isDirty || !verified}
            type="submit"
            data-test="submit-payout-account"
            colorScheme="primary"
          >
            Add Account
          </Button>
          {isDirty && (
            <Button
              disabled={isSubmitting}
              data-test="reset-login"
              onClick={() => resetForm(defaultValues)}
            >
              Discard Changes
            </Button>
          )}
        </VStack>
      </form>
    </>
  );
};
