import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AccountStatus, AccountType } from "@obtainly-v2/enums";
import {
  AccountModel,
  AdministratorModel,
  UserModel,
} from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { Card, CardBody } from "components/common/Card";
import { useToast } from "hooks/useToast";
import Link from "next/link";
import pluralize from "pluralize";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RiCheckFill, RiFileCopyFill, RiFileInfoFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { accountService } from "services";
import { PayoutAccountForm } from "./PayoutAccountForm";

interface PayoutAccountCardProps {
  user: UserModel | null;
  admin?: AdministratorModel;
  onlyActive?: boolean;
}

export const PayoutAccountCard: FC<PayoutAccountCardProps> = ({
  user,
  admin,
  onlyActive,
}) => {
  const { toast } = useToast();
  const [isEditing, setEditing] = useState(false);

  const {
    data: payoutAccounts,
    refetch: refetchPayoutAccounts,
    isLoading,
  } = useQuery(
    `account_read_by_type_${AccountType.NUBAN}_${user?._id}`,
    () =>
      accountService.readByOwnerType(user?.business!._id, AccountType.NUBAN),
    { retry: false, enabled: !!user?._id }
  );

  const { mutate: mutateAccountStatus, isLoading: isSwitchingAccount } =
    useMutation(accountService.updateAccountStatus);

  const activeAccount = useMemo(() => {
    return payoutAccounts?.find(
      (account) => account.status === AccountStatus.Active
    );
  }, [payoutAccounts]);

  const errorHandler = (error: any) => {
    const message = error?.response?.data?.message || "Unknown error occurred";
    toast({
      description: message,
      status: "error",
    });
  };

  const toggleAccountStatus = (params: {
    oldStatus: AccountStatus;
    newStatus: AccountStatus;
    _id: string;
  }) => {
    const { _id, oldStatus: old_status, newStatus: new_status } = params;
    return new Promise<void>((resolve, reject) => {
      mutateAccountStatus(
        { _id, old_status, new_status },
        {
          onSuccess: async () => {
            await refetchPayoutAccounts();
            resolve();
          },
          onError: (error) => {
            errorHandler(error);
            reject();
          },
        }
      );
    });
  };

  const onCloseModal = () => setEditing(false);

  return (
    <>
      {isLoading ? (
        <Card>
          <ContentLoader isLoading />
        </Card>
      ) : !!payoutAccounts?.length ? (
        <Card>
          <CardBody>
            {onlyActive ? (
              <>
                <Flex mb="10px" align="center" justify="space-between">
                  <Text color="gray.700" fontWeight="medium">
                    Payout Account
                  </Text>
                  <Text fontWeight="medium">
                    <Link href="/settings">Settings</Link>
                  </Text>
                </Flex>
                {!!activeAccount ? (
                  <PayoutAccountCardItem
                    account={activeAccount}
                    activeAccount={activeAccount}
                    isSwitchingAccount={isSwitchingAccount}
                    toggleStatus={toggleAccountStatus}
                  />
                ) : (
                  <Link href="/setting">
                    <Button
                      mt="20px"
                      w="full"
                      size="sm"
                      colorScheme="primary"
                      onClick={() => setEditing(true)}
                    >
                      Set Payout Account
                    </Button>
                  </Link>
                )}
              </>
            ) : (
              <>
                <Text color="gray.700" fontWeight="medium" mb="10px">
                  Payout {pluralize("Account", payoutAccounts?.length)}
                </Text>
                <VStack gap="10px" align="stretch" divider={<Divider />}>
                  {payoutAccounts.map((payoutAccount, index) => (
                    <PayoutAccountCardItem
                      key={index}
                      account={payoutAccount}
                      activeAccount={activeAccount}
                      isSwitchingAccount={isSwitchingAccount}
                      toggleStatus={toggleAccountStatus}
                    />
                  ))}
                </VStack>
                <Button
                  mt="20px"
                  w="full"
                  size="sm"
                  colorScheme="primary"
                  onClick={() => setEditing(true)}
                >
                  Add Payout account
                </Button>
              </>
            )}
          </CardBody>
        </Card>
      ) : (
        !!user && (
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() => setEditing(true)}
          >
            Add Payout account
          </Button>
        )
      )}
      <Modal isOpen={isEditing && !!user} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Add Payout Account</ModalHeader>
          <ModalBody pt="0px !important">
            <Alert
              status="warning"
              borderRadius="md"
              borderColor="warning.200"
              bg="warning.100"
              alignItems="start"
              mb="10px"
            >
              <AlertIcon as={RiFileInfoFill} w="24px" h="24px" />
              <AlertDescription p="0px">
                <Text fontSize="14px" color="gray.700">
                  Ensure that you own this payout account. All funding would be
                  paid to your payout account.
                </Text>
              </AlertDescription>
            </Alert>
            <PayoutAccountForm
              user={user!}
              admin={admin}
              onSuccess={() => {
                onCloseModal();
                refetchPayoutAccounts();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

interface PayoutAccountCardItemProps {
  account: AccountModel;
  activeAccount?: AccountModel;
  isSwitchingAccount?: boolean;
  toggleStatus?: (params: {
    oldStatus: AccountStatus;
    newStatus: AccountStatus;
    _id: string;
  }) => Promise<void>;
}

export const PayoutAccountCardItem: FC<PayoutAccountCardItemProps> = ({
  account,
  activeAccount,
  isSwitchingAccount,
  toggleStatus,
}) => {
  const payoutAccount = useMemo(() => {
    if (!account) return null;
    try {
      return JSON.parse(account.meta);
    } catch (error) {
      return null;
    }
  }, [account]);
  const [copied, setCopied] = useState(false);
  const isActive = account._id === activeAccount?._id;
  const [isUpdating, setUpdating] = useState(false);

  const onToggleStatus = useCallback(async () => {
    setUpdating(true);
    const newStatus =
      account.status === AccountStatus.Active
        ? AccountStatus.Inactive
        : AccountStatus.Active;
    if (activeAccount && !isActive) {
      await toggleStatus?.({
        _id: activeAccount._id,
        oldStatus: activeAccount.status,
        newStatus: AccountStatus.Inactive,
      });
    }
    await toggleStatus?.({
      _id: account._id,
      oldStatus: account.status,
      newStatus: newStatus,
    });

    setUpdating(false);
  }, [account, activeAccount, isActive, toggleStatus]);

  useEffect(() => {
    if (copied) {
      const copiedTimeout = setTimeout(() => {
        setCopied(false);
        clearTimeout(copiedTimeout);
      }, 5000);
    }
  }, [copied]);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <Text fontWeight="medium">
          {payoutAccount?.bank?.name}{" "}
          {isActive ? (
            <Badge colorScheme="success" variant="solid">
              Active
            </Badge>
          ) : (
            <Button
              size="xs"
              colorScheme="gray"
              disabled={isUpdating || isSwitchingAccount}
              onClick={onToggleStatus}
            >
              Set as Active
            </Button>
          )}
        </Text>
        <Flex align="center" justify="start">
          <Text fontSize="lg" fontWeight="bold">
            {payoutAccount?.account_number}
          </Text>
          <Center
            ml="6px"
            width="28px"
            height="28px"
            bg={copied ? "success.50" : "primary.50"}
            borderRadius={999}
            cursor="default"
            onClick={() => {
              navigator.clipboard.writeText(payoutAccount?.account_number);
              setCopied(true);
            }}
          >
            <Icon
              as={copied ? RiCheckFill : RiFileCopyFill}
              color={copied ? "success.500" : "primary.500"}
              width="18px"
              height="18px"
            />
          </Center>
        </Flex>
        {copied && (
          <Text fontSize="sm" color="success.600">
            Account number copied!
          </Text>
        )}
        <Text fontSize="sm" fontWeight="medium">
          {payoutAccount?.account_name}
        </Text>
      </Box>
    </Flex>
  );
};
