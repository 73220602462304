import { Box, Button, Text } from "@chakra-ui/react";
import { UserModel } from "@obtainly-v2/schema";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { accountService } from "services";
import { getMatchedWords } from "utils";

interface VerifyAccountProps {
  user: UserModel;
  bankCode: string;
  accountNumber: string;
  onVerify?: (status: boolean) => void;
  threshold?: number;
}

export const VerifyAccount: React.FC<VerifyAccountProps> = ({
  user,
  bankCode,
  accountNumber,
  onVerify,
  threshold = 0.6,
}) => {
  const isValidInput = useMemo<boolean>(() => {
    if (
      !bankCode ||
      bankCode.match(/\D/)?.length ||
      accountNumber.length !== 10 ||
      accountNumber.match(/\D/)?.length
    )
      return false;
    return true;
  }, [bankCode, accountNumber]);

  const {
    data,
    isLoading: isVerifyingPayoutAccount,
    refetch,
    isRefetching,
  } = useQuery(
    `verify_bank_account_${bankCode}_${accountNumber}`,
    () => accountService.verifyPayoutAccount({ bankCode, accountNumber }),
    {
      enabled: isValidInput,
      cacheTime: 1,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const verify = useMemo(() => {
    onVerify?.(false);
    if (!data) return null;
    if (data.code !== 200 || !data.data?.status) return null;
    const accountName = data.data.data?.account_name;
    if (accountName && user.business) {
      const { ratio, ...rest } = getMatchedWords(
        accountName,
        user.business?.name
      );
      if (ratio > threshold * 100) {
        onVerify?.(true);
        return {
          status: true,
          accountName,
        };
      } else {
        return {
          status: false,
          accountName,
        };
      }
    }
    return null;
  }, [data, user.business, threshold, onVerify]);

  return (
    <Box mt="6px">
      {isVerifyingPayoutAccount || isRefetching ? (
        <Text textDecoration="italics">Verifying...</Text>
      ) : isValidInput ? (
        !verify ? (
          <Text>
            Unable to verify account.{" "}
            <Button variant="link" onClick={() => refetch()}>
              Try again
            </Button>
          </Text>
        ) : verify?.status ? (
          <Text color="success.600" fontSize="sm">
            Account verified ({verify.accountName})
          </Text>
        ) : (
          <Text color="critical.600" fontSize="sm">
            Account name ({verify.accountName}) did not match business name (
            {user.business?.name})
          </Text>
        )
      ) : null}
    </Box>
  );
};
