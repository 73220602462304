import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { ContentLoader } from "components/common";
import { Card, CardBody } from "components/common/Card";
import Link from "next/link";
import { useQuery } from "react-query";
import { ledgerService } from "services";
import { formatCurrency } from "utils";

interface CreditBalanceCardProps {
  businessId: string;
}

export const CreditBalanceCard: React.FC<CreditBalanceCardProps> = ({
  businessId,
}) => {
  const { data, isLoading } = useQuery(
    "ledger/statement",
    () => ledgerService.statement(businessId),
    { enabled: !!businessId, retry: false }
  );

  const currencyOptions = { showCurrency: true };

  const balance = data?.ledger?.balance;
  const bookBalance = data?.ledger?.book_balance;
  const showBookBalance =
    !!bookBalance && bookBalance > 0 && bookBalance !== balance;

  return (
    <>
      <Card mt="20px">
        {isLoading ? (
          <ContentLoader isLoading />
        ) : (
          <CardBody>
            <Box>
              <SimpleGrid templateColumns="1fr auto">
                <Text color="gray.700" fontWeight="medium">
                  Total Credit Balance
                </Text>
                {showBookBalance && (
                  <Text fontWeight="medium">
                    <Link href="/inflow">Manage fund</Link>
                  </Text>
                )}
              </SimpleGrid>
              <Text
                fontSize="3xl"
                fontWeight="bold"
                color={data?.ledger ? "body" : "gray.400"}
              >
                {formatCurrency((balance || 0) / 100, currencyOptions)}
              </Text>
            </Box>
            {showBookBalance && (
              <Flex align="center">
                <Text>Book Balance</Text>
                <Text ml="6px" fontWeight="semibold">
                  {formatCurrency(bookBalance / 100, currencyOptions)}
                </Text>
              </Flex>
            )}
          </CardBody>
        )}
      </Card>
    </>
  );
};
