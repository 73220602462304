import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AccountStatementStatus } from "@obtainly-v2/enums";
import { AdministratorModel, LedgerLogModel } from "@obtainly-v2/schema";
import { ContentLoader, List } from "components/common";
import { Card, CardBody } from "components/common/Card";
import { Table } from "components/common/Table";
import { routes } from "config/routes";
import { useAccountStatementFilter } from "hooks/useAccountStatementFilter";
import NextLink from "next/link";
import React, { useMemo } from "react";
import { Column } from "react-table";
import {
  filterAccountStatement,
  formatCurrency,
  formatUnixTime,
  getAccountStatementStatus,
  parseJSON,
} from "utils";
import { AccountStatementStatusBadge } from "./AccountStatementStatusBadge";

interface AccountStatementTableProps {
  businessId?: string;
  admin?: AdministratorModel | null;
  filter?: AccountStatementStatus;
}

export const AccountStatementTable: React.FC<AccountStatementTableProps> = ({
  businessId,
  admin,
  filter,
}) => {
  const screenView = useBreakpointValue({
    base: "mobile",
    md: "desktop",
  });

  const { data, isLoading, queryProps, setQueryProps } =
    useAccountStatementFilter({ admin, businessId });

  const logs = useMemo(() => {
    if (data?.ledger_logs?.all?.list) {
      if (admin) {
        return [...data?.ledger_logs?.all?.list].reverse();
      } else if (!admin && !filter) {
        return [...data?.ledger_logs?.all?.list].reverse();
      }
      return filterAccountStatement({
        data: data?.ledger_logs?.all?.list,
        filterValue: filter,
      }).reverse();
    }
    return [];
  }, [admin, data?.ledger_logs?.all?.list, filter]);

  const columns = React.useMemo(() => {
    const defaultColumns: Column<LedgerLogModel>[] = [
      {
        id: "created",
        Header: "Date",
        accessor: "created",
        Cell: ({ cell }) => (
          <>{formatUnixTime(cell.value * 1000, "dd MMM yyy, hh:mm")}</>
        ),
      },
      {
        id: "transaction",
        Header: "Transaction",
        accessor: "tag",
        Cell: ({ cell }) => (
          <Text textTransform="capitalize">
            {getAccountStatementStatus(cell.value)}
          </Text>
        ),
      },
      {
        id: "value",
        Header: "Value",
        accessor: "value",
        Cell: ({ cell }) => (
          <Text
            color={
              cell.value > 0
                ? "success.600"
                : cell.value < 0
                ? "critical.600"
                : "gray.600"
            }
            textAlign="right"
          >
            {formatCurrency(cell.value / 100)}
          </Text>
        ),
      },
      {
        id: "balance",
        Header: "Balance",
        accessor: "balance",
        Cell: ({ cell }) => (
          <Text textAlign="right">{formatCurrency(cell.value / 100)}</Text>
        ),
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => {
          const description = parseJSON<any>(row.original.description);
          return (
            <Box>
              <Text>{description.narration}</Text>
            </Box>
          );
        },
      },
      {
        id: "reference",
        Header: "Ref.",
        accessor: "reference",
      },
      {
        id: "actions",
        Header: "Action",
        Cell: ({ row }: any) => (
          <NextLink
            href={`${
              !!admin ? routes.admin.accountStatement : routes.user.transactions
            }/${row.original?._id}`}
            passHref
          >
            <Link>
              <Button size="sm">View</Button>
            </Link>
          </NextLink>
        ),
      },
    ];

    return defaultColumns;
  }, [admin]);

  const renderMobileRow = (item: LedgerLogModel) => {
    const description = parseJSON<any>(item.description);
    return (
      <Card cursor="pointer" key={item._id}>
        <CardBody>
          <Flex justifyContent="space-between">
            <Text fontWeight="medium">
              {formatUnixTime(item.created * 1000, "d MMM yyy, hh:mm")}
            </Text>
            <Text
              fontWeight="semibold"
              color={
                item.value > 0
                  ? "success.600"
                  : item.value < 0
                  ? "critical.600"
                  : "gray.600"
              }
            >
              {formatCurrency(item.value / 100)}
            </Text>
          </Flex>
          <Text fontSize="14px" color="gray.700" mt="2px">
            {description?.narration ?? "-"}
          </Text>
          <Flex justifyContent="space-between" alignItems="center" mt="12px">
            <Text fontSize="14px" color="gray.700">
              {item.reference}
            </Text>

            <AccountStatementStatusBadge status={item.tag} />
          </Flex>

          <NextLink
            href={`${
              !!admin ? routes.admin.accountStatement : routes.user.transactions
            }/${item._id}`}
            passHref
          >
            <Link>
              <Button colorScheme="primary" variant="link" size="sm" pt="15px">
                View
              </Button>
            </Link>
          </NextLink>
        </CardBody>
      </Card>
    );
  };

  return (
    <Box>
      {/* Show list on mobile devices */}
      <List
        display={["block", "block", "none"]}
        items={logs ?? []}
        renderItem={renderMobileRow}
      />

      {/* Show table on desktop devices */}
      <Table
        tableContainerProps={{ display: ["none", "none", "block"] }}
        columns={columns}
        data={logs ?? []}
        queryProps={{
          ...queryProps,
          onQueryChange: (newProps) => {
            const updatedProps = { ...queryProps, ...newProps };
            setQueryProps(updatedProps);
          },
        }}
      />

      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!logs.length}
        errorMessage="No transactions found"
      />
    </Box>
  );
};
