import { LedgerSubhead } from "@obtainly-v2/enums";
import { AdministratorModel, LedgerModel } from "@obtainly-v2/schema";
import { TableQueryProps } from "components/common";
import { getUnixTime, subDays } from "date-fns";
import { atom, useAtom } from "jotai";
import { FilterProps } from "pages";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ledgerService } from "services";

interface AccountStatementTableProps {
  businessId?: string;
  admin?: AdministratorModel | null;
  filter?: FilterProps;
}

interface FilterAtom {
  ledgerSubHead: LedgerSubhead;
  month: any;
}

const initialAuthState: FilterAtom = {
  ledgerSubHead: LedgerSubhead.All,
  month: getUnixTime(new Date()),
};

export const accountFilterState = atom<FilterAtom>(initialAuthState);

export function useAccountStatementFilter({
  admin,
  businessId,
}: AccountStatementTableProps) {
  const [{ ledgerSubHead, month }, setAccountFilterState] =
    useAtom(accountFilterState);

  const [queryProps, setQueryProps] = useState<TableQueryProps>({
    page: 1,
    sort: { id: "created" as string, desc: true },
  });
  const owner = !!admin ? "*" : businessId;
  const queryArgs: [
    string,
    () => Promise<{ ledger: LedgerModel; ledger_logs: any }>,
    { enabled: boolean }
  ] = useMemo(() => {
    return [
      `ledger_statement${
        ledgerSubHead ? "_" + ledgerSubHead?.replaceAll("-", "_") : ""
      }${month ? month : ""}_${owner}`,
      () =>
        ledgerService.statement(`${owner}`, {
          subhead: ledgerSubHead ? ledgerSubHead : LedgerSubhead.Balance,
          from_timestamp: month
            ? getUnixTime(subDays(month, 30))
            : getUnixTime(subDays(new Date(), 30)),
        }),
      { enabled: !!admin || !!businessId },
    ];
  }, [owner, admin, businessId, ledgerSubHead, month]);

  const { data, isLoading } = useQuery(...queryArgs);

  return { data, isLoading, queryProps, setQueryProps, setAccountFilterState };
}
