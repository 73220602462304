import { Text } from "@chakra-ui/react";
import { Autocomplete, AutocompleteProps } from "components/common";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { accountService } from "services";

interface BanksAutocompleteProps extends AutocompleteProps {
  errorMessage?: string;
}

export const BanksAutocomplete: FC<BanksAutocompleteProps> = ({
  isDisabled,
  errorMessage,
  ...rest
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const { data: banks, isLoading } = useQuery(
    `account_lookup_banks`,
    accountService.readBanks
  );

  useEffect(() => {
    const newBanks =
      banks
        ?.filter((bank) => bank.active)
        ?.map((bank) => ({
          label: bank.name,
          value: bank.code,
        })) || [];
    setOptions(newBanks);
  }, [banks]);

  return (
    <>
      <Autocomplete
        options={options}
        isDisabled={isLoading || !!isDisabled}
        {...rest}
      />
      {!!errorMessage && (
        <Text fontSize="sm" color="red.500" mt="2px">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
