import {
  Box,
  Icon,
  Skeleton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FC } from "react";
import { RiInformationFill } from "react-icons/ri";
import { formatCurrency } from "utils";

type AccountStatProps = {
  label: string;
  data?: {
    sum: number;
    count: number;
    description?: string;
  };
};

export const AccountStat: FC<AccountStatProps> = ({ label, data }) => {
  if (!data) return null;
  const { count, sum, description } = data;
  return (
    <Stat>
      <StatLabel>
        {label}{" "}
        {!!description && (
          <Tooltip label={description}>
            <Text as="span" color="gray.600">
              <Icon as={RiInformationFill} />
            </Text>
          </Tooltip>
        )}
      </StatLabel>
      <StatNumber color={sum >= 0 ? "success.600" : "critical.600"}>
        {formatCurrency(Math.abs(sum || 0) / 100, {
          showCurrency: true,
          decimals: 0,
        })}
      </StatNumber>
      <StatHelpText>Count: {count}</StatHelpText>
    </Stat>
  );
};

export const AccountStatSkeleton: FC = () => {
  return (
    <Box>
      <Skeleton height="15px" width="40%" mb="6px" />
      <Skeleton height="28px" width="80%" mb="6px" />
      <Skeleton height="10px" width="20%" mb="6px" />
    </Box>
  );
};
